import { AppContext, IAppContext } from "@vahak/core/dist/app-context";
import { Languages } from "@vahak/core/dist/constants/languages";
import { COOKIES, DefaultCookieOptions } from "@vahak/core/dist/constants/misc";
import { UserAction, UserActionRequiredResponse } from "@vahak/core/dist/constants/userAction";
import { MY_LOAD_LORRY_TABS } from "@vahak/core/dist/constants/tab";
import CryptoJS from "crypto-js";

import { ROUTES } from "@vahak/core/dist/constants/routeStrings";
import { useSetAppContext } from "@vahak/core/dist/hooks/useSetAppContext";
import { useTranslation } from "next-i18next";
import { useCookies } from "react-cookie";
import { toast } from "@vahak/core/dist/components/toast/toast";
import dynamic from "next/dynamic";
import { getPathName } from "methods/getPathName";

import {
    handleUpdateCompanyDetails,
    useGetUserProfileDetails,
    useUpdateCompanyDetails
} from "@vahak/core/dist/_services/hooks/useUserProfileService";
import { useEffect } from "react";
import { useRouter } from "next/dist/client/router";
// import BulkMinimiseMaximise from "./components/min-max-bulk/BulkMinimiseMaximise";
import { useQueryClient } from "react-query";
import { QueryNames } from "@vahak/core/dist/_services/hooks/queryNames";
import { ModalStateNames } from "@vahak/core/dist/custom-types/modal-state";
import { CompanyData } from "./services/company/useGetCompanyDetails";
import { getAllVwoABTestVariations } from "./methods/getABTestVariation";
import { useEventTrackerService } from "@vahak/core/dist/_services/hooks/useEventTrackerService";
import { GA4EventNames } from "@vahak/core/dist/constants/event-names";
import { moEngageUserAttributeTrack } from "@vahak/core/dist/_services/user-analytics.service";

const SupportMenu = dynamic(
    () =>
        import(
            /* webpackChunkName: "SupportMenu", webpackPrefetch: true */ "./components/misc/callback-request/support-menu/SupportMenu"
        ).catch(() => {
            return () => <></>;
        }),
    { ssr: false }
);

// polyfill of Object.fromEntries for old browsers. react-query is using Object.fromEntries which causes error in old browsers.
if (!Object.fromEntries) {
    Object.fromEntries = function (entries: any) {
        if (!entries || !entries[Symbol.iterator]) {
            throw new Error("Object.fromEntries() requires a single iterable argument");
        }
        let obj: any = {};
        for (let [key, value] of entries) {
            obj[key] = value;
        }
        return obj;
    };
}

function AppContextWrapper({ children, isMobile }: any) {
    const { i18n } = useTranslation();
    const [cookies, setCookie] = useCookies<string>(["numOfContactRqsts"]);
    const { mutateAsync: updateCompanyDetails } = useUpdateCompanyDetails();
    const router = useRouter();
    const tokenDetails = router.query["td"];

    const { context, setContext } = useSetAppContext();
    const { sendGAandMoEngageEvent } = useEventTrackerService();

    const queryClient = useQueryClient();

    /**
     * User company details - api call
     * On page load this api will be called and updated in context
     * Coverage - Nextjs package
     * * Any changes should be changed in both AppContextWrapper nextjs and admin
     */

    useGetUserProfileDetails(
        { companyId: Number(context.companyId) },
        {
            enabled: Boolean(context.isPhoneVerified && Number(context.companyId)),
            refetchInterval: 0,
            retry: 0,
            refetchOnWindowFocus: false,
            onError: () => {},
            onSuccess: (data) => {
                const companyData = data as CompanyData | undefined;

                if (context?.globalData?.is_login_successful) {
                    moEngageUserAttributeTrack("total_loads", companyData?.total_loads);
                    moEngageUserAttributeTrack("total_lorries", companyData?.total_lorries);
                    moEngageUserAttributeTrack("total_connections", companyData?.total_connections);
                    moEngageUserAttributeTrack("user_rating", companyData?.avg_rating);
                    moEngageUserAttributeTrack("materials_handled", companyData?.handled_materials);
                    moEngageUserAttributeTrack(
                        "routes",
                        companyData?.routes?.map((r) => r.name)
                    );
                }

                const coinsData = {
                    coinsTotal: Math.ceil(companyData?.total_cashback_amount ?? 0),
                    coinsBlocked: Math.ceil(companyData?.blocked_cashback_amount ?? 0),
                    coinsExpiringToday: Math.ceil(companyData?.coins_expiring_today ?? 0),
                    coinsAvailable: 0
                };
                coinsData["coinsAvailable"] = Math.max(coinsData.coinsTotal - coinsData.coinsBlocked, 0);

                const membershipData = {
                    membershipRequired: companyData?.membership_required,
                    haveMembership: companyData?.have_membership,
                    membershipStatus: companyData?.membership_status,
                    membershipPlanName: companyData?.membership_plan_name
                };

                setContext(
                    (state: any) =>
                        ({
                            ...state,
                            isAadhaarVerified: data?.is_aadhaar_verified,
                            isPanVerified: data?.is_pan_verified,
                            isGstVerified: data?.is_gst_verified,
                            userCompanyInfo: {
                                cityName: data?.city,
                                stateName: data?.state,
                                placeId: data?.company_place_id
                            },
                            taggedAgent: data?.agent_details,
                            ...coinsData,
                            ...membershipData
                        } as IAppContext)
                );
                context.setCompanyId?.(data?.id);
                context.setName(data?.name);
                context.setServiceType(data?.service_type);
                context.setCompanyLogo(data?.logo);
                context.setUserId(data?.user_id);
            }
        }
    );

    useEffect(() => {
        setContext((state: any) => ({
            ...state,
            lang: Languages.find((lang) => lang.value === i18n.language)?.id,
            setLang: (lang: any) => {
                const language = Languages.find((langObj) => langObj.id === lang);

                if (i18n.language !== language?.value) {
                    // i18n.changeLanguage(language?.value);
                    router.push({ pathname: getPathName(), query: { ...router.query } }, undefined, {
                        locale: language?.value || "en"
                        // shallow: true
                    });
                    if (cookies[COOKIES.companyId]) {
                        handleUpdateCompanyDetails(updateCompanyDetails, {
                            companyId: cookies[COOKIES.companyId],
                            languageCode: lang
                        });
                    }

                    toast.success(`Language changed to ${language?.labelInLanguage || "English"}`);
                }
            },

            /**
             * This is to close the kyc modal when router is changed
             */
            showMandateKycPopup: false,
            showGstVerificationPopup: false
        }));
    }, [router.pathname]);

    useEffect(() => {
        const referralCode = new URLSearchParams(router.asPath.split("?")[1]).get("referral_code");
        if (referralCode && context.setReferralCode) {
            context.setReferralCode?.(referralCode as string);
        }
    }, [context.setReferralCode]);

    useEffect(() => {
        setContext((state: any) => ({
            ...state,
            hostURL: window.location.origin,
            setShowUpgradeMembershipPlanPopup: (
                val: boolean,
                targetAction?:
                    | UserAction.POST_LOAD
                    | UserAction.LOAD_BID
                    | UserAction.POST_LORRY
                    | UserAction.LORRY_BID
                    | "default",
                membershipStatus?:
                    | UserActionRequiredResponse.BUY_MEMBERSHIP
                    | UserActionRequiredResponse.TOP_UP_MEMBERSHIP
                    | UserActionRequiredResponse.UPGRADE_MEMBERSHIP
                    | UserActionRequiredResponse.DAILY_MEMBERSHIP_LIMIT_EXHAUSTED
                    | UserActionRequiredResponse.MEMBERSHIP_EXPIRED
            ) => {
                setContext((state: any) => ({
                    ...state,
                    showUpgradeMembershipPlanPopup: val,
                    upgradeModalTargetAction: targetAction,
                    upgradeModalMembershipStatus: membershipStatus,
                    mandateUserVerificationCallBackFunction: undefined
                }));
            },
            setShowMandateKycPopup: (val: boolean) => {
                setContext((state: any) => ({
                    ...state,
                    showMandateKycPopup: val,
                    mandateUserVerificationCallBackFunction: undefined
                }));
            },
            setShowGstVerificationPopup: (val: boolean) => {
                setContext((state: any) => ({
                    ...state,
                    showGstVerificationPopup: val,
                    mandateUserVerificationCallBackFunction: undefined
                }));
            },
            setMandateUserVerificationCallBackFunction: (cb: () => void) => {
                setContext((state: any) => ({
                    ...state,
                    mandateUserVerificationCallBackFunction: cb
                }));
            },
            hideMandateKycPop: () => {
                setContext((state: any) => ({
                    ...state,
                    showMandateKycPopup: false,
                    mandateUserVerificationCallBackFunction: undefined
                }));
            },
            resetMandateKycPop: () => {
                setContext((state: any) => ({
                    ...state,
                    showMandateKycPopup: true,
                    mandateUserVerificationCallBackFunction: undefined
                }));
            },
            revalidateGetDocsStatus: () => queryClient.invalidateQueries([QueryNames.getDocumentsNonMutate]),
            setModalState: (modalState: { [key in ModalStateNames]?: boolean }) => {
                setContext((state: any) => ({
                    ...state,
                    modalState: {
                        ...modalState
                    }
                }));
            },
            setMandateUserVerificationActionTriggered: (val: boolean) => {
                setContext((state: any) => ({
                    ...state,
                    mandateUserVerificationActionTriggered: val
                }));
            },
            setMandateUserVerificationActionType: (val: string) => {
                setContext((state: any) => ({
                    ...state,
                    mandateUserVerificationActionType: val
                }));
            },
            setShowBankAddPopup: (val: boolean, cb: Function) => {
                setContext((state: any) => ({
                    ...state,
                    showBankAddPopup: val,
                    onSuccessAddBankCallback: cb
                }));
            },
            setShowShareModalPopup: ({
                isVisible,
                mktType,
                data
            }: {
                isVisible: boolean;
                mktType: "load" | "lorry";
                data: any;
            }) => {
                setContext((state: any) => ({
                    ...state,
                    showShareModalPopup: { isVisible, mktType, data }
                }));
            },
            ...getAllVwoABTestVariations(),
            setAbTestVariations: () => {
                setContext((state: any) => ({
                    ...state,
                    ...getAllVwoABTestVariations()
                }));
            },
            setShowClearDuePopup: ({ isOpen, dueAmount }: any) => {
                setContext((state: any) => ({
                    ...state,
                    showClearDuePopup: isOpen,
                    dueAmount: dueAmount,
                    mandateUserVerificationCallBackFunction: undefined
                }));
            }
        }));
    }, []);

    useEffect(() => {
        if (tokenDetails) {
            const bytes = CryptoJS.AES.decrypt(
                tokenDetails.toString(),
                process.env.NEXT_PUBLIC_THIRD_PARTY_COOKIE_ENCRYPTION_KEY ?? ""
            );
            const decryptedData: any = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            const { ci, vt } = decryptedData;
            if (ci && vt) {
                setContext((state: any) => ({
                    ...state,
                    companyId: ci,
                    isPhoneVerified: true,
                    thirdPartyCompanyName: "Roadcast"
                }));
                setCookie(COOKIES.thirdPartyCompanyName, "Roadcast", DefaultCookieOptions);
                setCookie(COOKIES.token, vt, DefaultCookieOptions);
                setCookie(COOKIES.isVerified, 1, DefaultCookieOptions);

                sendGAandMoEngageEvent({
                    name: GA4EventNames.THIRD_PARTY.third_party_login,
                    data: {
                        third_party_company_name: "Roadcast",
                        company_id: ci
                    }
                });
            }
            delete router.query["td"];
        }
    }, [tokenDetails]);

    /**
     * Since aspath will return pathname along with query params
     * router.asPath.split("?")[0] - added this condition
     */
    const hideSupportMenuInExcludedRoutes = [
        ROUTES.myLoadsMyLorries.getUrl("loads", MY_LOAD_LORRY_TABS.LISTING),
        ROUTES.myLoadsMyLorries.getUrl("loads", MY_LOAD_LORRY_TABS.BIDS),
        ROUTES.myLoadsMyLorries.getUrl("lorries", MY_LOAD_LORRY_TABS.LISTING),
        ROUTES.myLoadsMyLorries.getUrl("lorries", MY_LOAD_LORRY_TABS.BIDS),
        ROUTES.help.getUrl(),
        "/mob/blogs/[BlogPost]"
    ].includes(router.asPath.split("?")[0]);

    return (
        <AppContext.Provider value={{ ...context, isMobile }}>
            {/* <BulkMinimiseMaximise /> */}
            {children}
            {isMobile && !hideSupportMenuInExcludedRoutes && <SupportMenu />}
        </AppContext.Provider>
    );
}

export default AppContextWrapper;
