export const MARKETPLACE_ROUTE = "marketplace";
export const LISTING_PAGE = "myLoadLorries";
export const BULK_LOADS_LIST = "bulkLoads";
export const PREMIUM_LOAD_TRIP = "premiumLoadsTrip";
export const USER_PROFILE_PAGE = "userProfile";
export const CS_GLOBAL_SEARCH_PAGE = "cs_global_search_page";

export const MOBILE_APP = "mobileApp";
export const PAYMENT_LIST = "paymentList";
export const CASHBACK_STATUS_PAGE = "cashbackStatusPage";
export const PHYSICAL_VERIFY_DOC = "physicalVerifyDoc";
export const TRACK_VEHICLE = "trackVehicle";
export const MW_TRACK_VEHICLE = "mwTrackVehicle";
export const MW_PHYSICAL_VERIFY_DOC = "mwPhysicalVerifyDoc";
export const TAG_AGENT_REFERRAL_PAGE = "tagAgent";
export const TAG_AGENT_LOAD_PAGE = "tagAgentLoadPage";
export const TAG_AGENT_SINGLE_USER_LOAD_PAGE = "tagAgentSingleUserLoadPage";
export const TAG_AGENT_LORRY_PAGE = "tagAgentLorryPage";
export const TAG_AGENT_SINGLE_USER_LORRY_PAGE = "tagAgentSingleUserLorryPage";
export const TAG_AGENT_ENQUIRY_CARD_PAGE = "tagAgentEnquiryCardPage";
export const TAG_AGENT_CUSTOMER_PROFILE = "tagAgentCustomerProfile";

export const MW_HOME = "mwHome";
export const PREMIUM_CLIENT_LISTING = "premiumClientListing";
export const PREMIUM_ROUTE = "premiumRoute";

export const HOME_ROUTE = "homeRoute";
export const DASHBOARD_ROUTE = "dashboard";
export const TRANSPORT_DIRECTORY = "transportDirectory";

export const ADMIN_LOGIN = "adminLogin";
export const ADMIN_HOME = "adminHome";

export const CUSTOMER_LOGIN = "customerLogin";
export const BLOCK_USER = "blockUser";
export const SEND_WHATSAPP_MESSAGE = "sendWhatsappMessage";

export const EXECUTIVES_USER = "executivesUser";
export const EXECUTIVES_ROLE = "executivesRole";

export const TICKET_SYSTEM_ROUTE_AADHAAR = "ticketSystemAadhaar";
export const TICKET_SYSTEM_ROUTE_PAN = "ticketSystemPan";
export const TICKET_SYSTEM_ROUTE_BUSINESS = "ticketSystemBusiness";
export const TICKET_SYSTEM_ROUTE_EWAY = "ticketSystemEway";
export const TICKET_SYSTEM_ROUTE_RC = "ticketSystemRC";
export const TICKET_SYSTEM_ROUTE_PAYMENT_REFUND = "ticketSystemPaymentRefund";
export const TICKET_SYSTEM_ROUTE_ACCOUNT_DELETION = "ticketSystemAccountDeletion";
export const TICKET_SYSTEM_ROUTE_VAS_LEADS = "ticketSystemVasLeads";
export const TICKET_SYSTEM_ROUTE_HELP = "ticketSystemHelp";
export const TICKET_SYSTEM_ROUTE_BID_CANCELLATION = "ticketSystemBidCancellation";

export const MEMBERSHIP = "membership";
export const MEMBERSHIP_PAYMENT = "membership-payment";
export const MEMBERSHIP_UPGRADE = "membership-upgrade";
export const MEMBERSHIP_HISTORY = "membership-history";
export const MEMBERSHIP_FEATURE = "membership-feature";
export const BANK_DETAILS = "bank-details";
export const TRANSPORT_NAGAR = "transport-nagar";
export const MY_LORRIES_PAGE = "MY_LORRIES_PAGE";

//Report system routes
export const PAYMENT_REPORT_SYSTEM_ROUTE_REAL_TIME_TRANSACTION = "payment-report-system-real-time-transaction";
export const PAYMENT_REPORT_SYSTEM_ROUTE_TAP_N_GO_DEPOSIT_RECORDS = "payment-report-system-tap-n-go-deposit-record";
